<template>
  <div class="comment font-sm" :data-depth="depth" :class="{skeleton}">
    <div class="wrapper">
      <router-link :to="`/channel/${memberSeq}`" class="no-underline" v-if="!(memberAuth && memberAuth.includes('ADMIN'))">
        <MemberPicture :memberSeq="memberSeq" size="32" class="pic"/>
      </router-link>
      <MemberPicture :memberSeq="memberSeq" size="32" class="pic" v-else/>
      <div class="root">
        <div class="profile">
          <span class="name" v-if="memberAuth && memberAuth.includes('ADMIN')">오마이컴퍼니</span>
          <span class="name" v-else-if="hideInvestorNameYn === 'Y'">키다리 후원자</span>
          <router-link :to="`/channel/${memberSeq}`" class="no-underline" v-else>
            <span class="name">{{ memberName }}</span>
          </router-link>
          <span class="font-xs pl-2" v-if="createDate">{{ createDate }}</span>
          <span class="font-xs pl-2" v-if="page === 'cheer' && !state.isReply">{{ $lib.getNumberFormat(totalAmt) }}원 후원</span>
        </div>
        <div class="dropdown" v-if="computedVisibleBlockMenu || computedVisibleEditMenu">
          <a type="button" data-toggle="dropdown">
            <span class="img" style="background-image: url(/assets/ico/component.comment.dot-menu.svg)"></span>
          </a>
          <div class="dropdown-menu shadow">
            <template v-if="computedVisibleEditMenu">
              <a class="dropdown-item" @click="edit(idx)" v-if="restoreFlag !== 'N'">
                <div>
                  <span>수정하기</span>
                  <span class="img" style="background-image: url(/assets/ico/common.edit.svg)"></span>
                </div>
              </a>
              <a class="dropdown-item" @click="remove(idx)">
                <div>
                  <span>삭제하기</span>
                  <span class="img" style="background-image: url(/assets/ico/common.trash.svg)"></span>
                </div>
              </a>
            </template>
            <template class="block" v-if="computedVisibleBlockMenu">
              <a class="dropdown-item" @click="hide(idx)" title="차단하기" v-if="!commentBlindSeq || restoreFlag === 'Y'">
                <div>
                  <span>차단</span>
                </div>
              </a>
              <a class="dropdown-item" @click="restore(idx)" title="차단 해제하기" v-else>
                <div>
                  <span>차단 해제</span>
                </div>
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="content">
        <span v-html="content"></span>
      </div>
      <a class="color-secondary pointer no-underline reply" @click="reply(idx)" title="답글달기" v-if="computedVisibleReply">
        <span class="img" style="background-image: url(/assets/ico/component.comment.reply.svg)"></span>
        <span>답글달기</span>
      </a>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import MemberPicture from "@/components/MemberPicture";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "componentComment";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MemberPicture},
  mixins: [mixin],
  props: {
    content: String,
    commentBlindSeq: Number,
    createDate: String,
    depth: Number,
    edit: Function,
    hideInvestorNameYn: String,
    hide: Function,
    idx: Number,
    memberSeq: String,
    memberName: String,
    memberAuth: String,
    openId: String,
    page: String,
    restore: Function,
    restoreFlag: String,
    remove: Function,
    reply: Function,
    skeleton: Boolean,
    totalAmt: Number,
  },
  setup(props) {
    const component = new Component(() => {
      state.isAdmin = store.state.account.memberAuth?.includes("ADMIN");
      state.isMaker = props.openId && props.openId === store.state.account.memberSeq;
      state.isReply = props.depth !== 0;
    });

    const state = reactive({
      isAdmin: false,
      isMaker: false,
      isReply: false,
    });

    const computedVisibleBlockMenu = computed(() => {
      return !state.isReply && props.page === "cheer"
          && (state.isAdmin || state.isMaker);
    });

    const computedVisibleEditMenu = computed(() => {
      return (props.memberSeq && props.memberSeq === store.state.account.memberSeq)
          && (props.page !== "cheer" || props.page === "cheer" && state.isReply);
    });

    const computedVisibleReply = computed(() => {
      return !state.isReply && (state.isAdmin || state.isMaker) && props.restoreFlag !== "N" && (props.reply && typeof props.reply === "function");
    });

    return {
      component
      , state
      , computedVisibleBlockMenu
      , computedVisibleEditMenu
      , computedVisibleReply
    };
  }
});
</script>

<style lang="scss" scoped>
.comment {
  color: $colorTextDefault;
  margin-top: $px16;

  .wrapper {
    position: relative;
    padding-left: calc($px32 + $px8);
    padding-top: $px8;

    .pic {
      position: absolute;
      top: $px3;
      left: 0;
      border: $px1 solid #eee;
      border-radius: 50%;
    }

    .root {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $px16;

      .profile {
        .name {
          font-size: $px14;
        }

        .font-xs {
          color: #ccc;
        }
      }

      .dropdown {
        font-size: 0;

        > a {
          > .img {
            width: $px24;
            height: $px24;
          }
        }

        .dropdown-menu {
          border: 0;
          border-radius: $px8;
          font-size: $px14;
          left: initial !important;
          right: 0;
          padding: 0;
          transform: translate(0, $px24) !important;
          overflow: hidden;

          .dropdown-item {
            cursor: pointer;
            width: auto;
            padding: 0 $px16;

            > div {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: $px16 0;

              > .img {
                width: $px16;
                height: $px16;
              }
            }

            &:not(:last-child) {
              > div {
                border-bottom: $px1 solid #f2f2f2;
              }
            }

            &:active {
              background-color: #e9ecef;
              color: inherit;
            }
          }
        }
      }
    }

    .content {
      margin-top: $px4;
      word-break: break-all;
      white-space: pre-line;
    }

    .reply {
      margin-top: $px12;
      display: inline-block;

      > .img {
        width: $px16;
        height: $px16;
        vertical-align: middle;
        margin-right: $px4;
      }
    }
  }

  &[data-depth="1"] {
    margin-top: $px8;
    background-color: $colorBackground;
    border-radius: $px16;
    padding: $px16 $px10 $px16 calc($px10 + $px24 + $px4);
    position: relative;

    &:before {
      content: "";
      background-image: url(/assets/ico/component.comment.depth-pipe.svg);
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      width: $px24;
      height: $px24;
      position: absolute;
      top: $px18;
      left: $px10;
    }
  }

  &.skeleton {
    .wrapper {
      .img {
        @include skeleton;
      }

      .root {
        .profile {
          b, > span, a {
            @include skeleton;
          }
        }

        .dropdown > a {
          display: none;
        }
      }

      .content > span {
        @include skeleton;
      }

      .reply {
        @include skeleton;
      }
    }

    &:before {
      display: none;
    }
  }
}
</style>